:root {
  --netzsch-green: #007167;
  --nav-menu-bgr-color: #f7f7f7;
  --text-secondary-color: #979ea5;
  --text-primary-color: #264247;
  --text-disabled-color: lightgrey;
  --sidebar-xl-width: 370px;
  --sidebar-lg-width: 300px;
  --attribute-label-sm-width: 250px;
  --attribute-label-lg-width: 150px;
  --attribute-label-xl-width: 250px;
  --select-input-width: 300px;
  --duty-point-sm-width: 250px;
  --admin-details-container-width: 500px;
  --err-message-color: #bb0000;
  --inputs-margin-bottom: 25px;
}

.analyzerContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.analyzerLayout {
  overflow: hidden;
}

.stackContainer {
  overflow: hidden !important;
  width: 100% !important;
}

.stackContainerAdminUi {
  overflow: hidden !important;
  width: 100% !important;
  height: 100vh;
}

.adminPage {
  position: absolute;
  overflow: hidden;
  width: 100%;
}

.analyzerPage {
  position: absolute;
  overflow: hidden;
  margin-top: 54px;
  width: 100%;
}

.drawerMenu {
  flex-shrink: 0;
}

.drawerMenu .MuiDrawer-paper {
  position: absolute;
  box-sizing: border-box;
  width: 100vw;
}

.navigationMenu {
  background-color: var(--nav-menu-bgr-color) !important;
  position: relative;
  width: 100vw;
}

.detailsContainer {
  color: var(--text-secondary-color);
  width: 100vw;
  height: calc(100vh - 84px);
  margin: 0;
  box-shadow: none;
  overflow: hidden;
}

.mainContainerAdminUi {
  color: var(--text-secondary-color);
  width: 100vw !important;
  margin: 0;
  padding: 6px;
  box-shadow: none;
  overflow: auto;
}

.detailsTitleContainer {
  width: 100%;
  display: inline-block;
  padding-top: 0px;
  padding-bottom: 20px;
}

.detailsTitleText {
  float: left;
  max-width: calc(100% - 150px);
  font-weight: 600 !important;
  margin: 10px 8px 15px 8px !important;
  color: var(--text-primary-color);
}

.changeUnitsButton {
  float: right;
  text-transform: none !important;
  margin-right: 8px;
}

.sideBarHeader {
  height: 54px;
  box-shadow: 0;
  display: inline-block;
  background-color: var(--nav-menu-bgr-color);
  width: var(--sidebar-lg-width);
  align-items: center;
}

.sidebarAccordionContainer {
  overflow-y: auto;
  position: relative;
}

.sidebarAccordionContainerCpq {
  overflow-y: auto;
  position: relative;
}

.astSidebarButton {
  float: left;
  padding: 16px 0px 8px 16px;
  display: flex;
  margin-top: 8px !important;
  background-color: transparent !important;
}

.astSidebarButton:hover {
  cursor: pointer;
}

.astSidebarButtonLabel {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.astSidebarButtonIcon {
  transform: rotate(270deg);
  margin: 6px 0px 5px 16px;
}

.astLogo {
  margin-top: -31px;
  height: 100px !important;
  width: 100px !important;
}

.astSidebarAdminButton {
  margin-right: 10px !important;
  height: 35px;
  width: 35px;
  padding: 0px !important;
}
.astSidebarAdminButton:hover {
  background-color: transparent !important;
}

.astSidebarAdminIcon {
  height: 35px !important;
  width: 35px !important;
  margin-top: 25px;
}
.astSidebarAdminIcon:hover {
  background-color: transparent;
}

.astSideBarUserContainer {
  position: absolute;
  bottom: 0px;
}

.astSideBarUserContainerCpq {
  position: absolute;
  bottom: 40px;
}

.astSidebarUserButton {
  color: grey !important;
  height: auto;
}
.astSidebarUserButton:hover {
  background-color: transparent !important;
}

.astSidebarUserIcon {
  width: 50px !important;
  height: 50px !important;
}

.astSidebarUserLabel {
  font-size: 14px !important;
  font-weight: 600 !important;
  display: inline;
}

.breadcrumbNavContainer {
  position: relative;
  height: 30px;
  width: 100vw;
  overflow: hidden;
  padding-left: 10px;
  background-color: var(--nav-menu-bgr-color);
}

.breadcrumbRow {
  max-width: 40vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}

.breadcrumbArrow {
  color: var(--text-primary-color) !important;
}

.breadcrumbText {
  font-size: 12px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  color: var(--text-primary-color) !important;
}

.breadcrumbText:hover {
  background-color: none;
}

.disabledText {
  color: var(--text-disabled-color) !important;
}

.breadcrumbButton {
  font-size: 12px !important;
  text-transform: none !important;
  color: var(--text-primary-color) !important;
  background-color: var(--nav-menu-bgr-color) !important;
  padding-left: 0px !important;
}
.breadcrumbButtonRight {
  float: right;
}

.attributesContainer {
  overflow-y: auto;
  height: 100vh !important;
  padding-left: 15px;
  padding-right: 10px;
  overflow-x: hidden;
}

.attributesContainerWithFooter {
  height: calc(100vh - 47px) !important;
}

.attributesContainerWithFooterCPQ {
  height: calc(100vh - 87px) !important;
}

.attributeList {
  display: block;
  overflow: hidden;
}

.cardList {
  position: relative !important;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 16px;
  z-index: 0;
}

.graphAccordion {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
}

.dutyPointList {
  max-width: none;
}

.cardValue {
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 15px !important;
  margin-bottom: 5px !important;
  text-align: center !important;
}

.productCardValue {
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 15px !important;
  padding-top: 8px !important;
  margin-bottom: 5px !important;
}

.cardContent {
  display: flex;
  justify-content: center;
  height: 110px;
}

.productCardActions {
  justify-content: center;
  padding-top: 0 !important;
}

.searchCardContent {
  display: flex;
  height: 60px;
  justify-content: center !important;
}

.searchCardDescription {
  font-size: 12px;
  text-align: center !important;
  height: 60px !important;
}

.stepDescription {
  font-size: 12px !important;
}

.switchButton {
  font-weight: '600';
  font-size: 0.75em;
  margin-top: 10px;
}

.switchButtonLabel {
  display: inline;
}

.searchCardApproximation {
  font-size: 12px;
  color: chocolate;
  text-align: center !important;
  height: 40px !important;
}

.productCardContainer {
  padding: 16px;
}

.productRestrictionContainer {
  padding: 8px;
}

.productCardContent {
  height: 200px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.productDescription {
  font-size: 12px;
}

.productDescription :nth-child(1) {
  line-height: 12px !important;
  padding: 0px !important;
  margin: 0px !important;
}

.productDescription ul {
  margin-top: 5px !important;
  line-height: 12px;
}

.productCardImage {
  object-fit: contain !important;
  object-position: center !important;
  width: 100% !important;
  height: 120px !important;
  background-color: var(--nav-menu-bgr-color) !important;
  border-bottom: 1px solid #264247;
}

.productCardMissingImage {
  object-fit: contain !important;
  object-position: center !important;
  width: 100% !important;
  height: 120px !important;
  background-color: var(--nav-menu-bgr-color) !important;
  border-bottom: 1px solid #264247;
  text-align: center;
  padding-top: 50px;
}

.productErrorIcon {
  size: small;
  color: var(--err-message-color);
  float: left;
  position: absolute;
}

.productErrorIcon:hover {
  cursor: pointer;
}

.productCheckedIcon {
  size: small;
  float: right;
  position: absolute;
  right: 0;
}

.searchAttributeField {
  width: 100%;
  max-width: 500px;
}

.searchAttributeIcon {
  color: var(--text-disabled-color);
}

.cardActions {
  justify-content: center;
  height: 52px;
}

.cardSelectButton {
  width: 160px;
  text-transform: none !important;
}

.dutyPointCard {
  padding: 10px;
  margin-right: 10px;
  border: 1px solid var(--text-disabled-color);
  border-radius: 5px;
  box-shadow: 1px 3px 4px 0px var(--text-disabled-color);
  margin-bottom: 10px;
}

.addDutyPointContainer {
  width: 100%;
  background-color: var(--text-disabled-color);
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dutyPointButtonsContainer {
  margin-bottom: 8px;
  margin-top: 8px;
}

.dutyPointButton {
  text-transform: none !important;
}

.dutyPointCopyButton {
  float: right;
}

.dutyPointAddButton {
  left: 50%;
  transform: translateX(-50%);
}

.dutyPointAddText {
  font-size: 16px !important;
  font-weight: bold !important;
  text-align: center;
  margin-bottom: 10px !important;
}

.dutyPointSwitchContainer {
  margin-left: 0px !important;
}

.dutyPointSwitch {
  font-size: 16px !important;
  font-weight: bold !important;
}

.attributeUnitText {
  font-size: 12px !important;
  color: var(--text-secondary-color);
}

.commonInputContainer {
  width: 100%;
  display: flex;
  gap: 5px;
}

.textFieldInput {
  width: 100%;
  max-width: 600px !important;
  height: 37.25px !important;
}

.textFieldInput fieldset > legend {
  height: 0px;
}

.textFieldInput .MuiOutlinedInput-notchedOutline {
  border-color: inherit;
  clear: both !important;
  top: 0;
}

.textFieldInput .MuiOutlinedInput-input {
  padding: 10px 5px 10px 12px;
}

.textFieldInput .MuiSvgIcon-root {
  color: var(--netzsch-green) !important;
}

.textFieldInput:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--netzsch-green) !important;
}

.textFieldInput .MuiFormHelperText-root {
  margin-left: 5px;
  margin-right: 5px;
}

.textFieldInput .MuiOutlinedInput-root {
  font-size: 0.75em;
  font-weight: 600;
  width: 100%;
}

.selectInput {
  width: 100%;
  max-width: 600px !important;
  display: block;
  font-size: 0.76em !important;
  font-weight: 600 !important;
  border-color: inherit;
}

.selectWrapper {
  position: relative;
}

.helperTextSelect {
  position: absolute;
  top: 37px;
  color: var(--err-message-color) !important;
}

.selectInput .MuiOutlinedInput-notchedOutline {
  border-color: inherit;
  clear: both !important;
}

.selectInput:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid var(--netzsch-green) !important;
}

.selectInput .MuiOutlinedInput-root {
  clear: both;
}

.selectInput .MuiOutlinedInput-root:hover {
  border: none;
}

.selectInput .MuiOutlinedInput-input {
  padding: 10px 5px 10px 12px;
  clear: 'both';
}

.selectInput .MuiSvgIcon-root {
  color: var(--netzsch-green);
}

.selectInputItem {
  font-size: 0.75em !important;
  font-weight: 600 !important;
}

.labelContainer {
  align-items: center;
  padding-right: 5px;
  height: fit-content !important;
  display: inline-block !important;
}

.selectLabel {
  padding-left: 8px;
  font-weight: 600 !important;
  font-size: 0.75em !important;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-primary-color);
}

.textFieldInputContainer {
  margin-bottom: var(--inputs-margin-bottom);
}

.textFieldInputContainerBlock {
  display: block;
  width: 100%;
  max-width: 298px;
}

.textFieldInputContainerBlock .textFieldInput {
  width: 100%;
  position: relative;
}

.textFieldInputContainerBlock .textFieldInput > p {
  position: absolute;
  top: 37px;
  color: var(--err-message-color) !important;
}

.textFieldInputContainerBlockDP {
  display: block;
  width: 100%;
  max-width: 247px;
  margin-bottom: 20px;
  line-height: 1em !important;
}

.textFieldInputContainerBlockDP .textFieldInput {
  width: 100%;
  position: relative;
  height: fit-content !important;
}

.textFieldInputContainerBlockDP .textFieldInput > p {
  position: absolute;
  top: 37px;
  color: var(--err-message-color) !important;
}

.textFieldInputContainerBlockDP .labelContainer {
  width: 100% !important;
}

.textFieldInput .MuiOutlinedInput-root {
  padding-right: 0px;
}

.unitAdornment {
  margin-right: 8px;
}

.selectContainer {
  margin-bottom: var(--inputs-margin-bottom);
}

.unitDialogInputsContainer {
  display: block;
  overflow-y: auto;
}

.unitInputContainer {
  height: 60px !important;
  margin-top: 10px !important;
}

.hintContainer {
  width: calc(100vw - 880px);
  padding-left: 10px;
}

.hintIcon {
  padding: 0px;
  margin-right: 5px;
  margin-left: 0px !important;
}

.hintIcon:hover {
  cursor: default !important;
}

.hintValue {
  padding: 8px 16px;
  font-size: 14px;
}

.hintValueSide {
  font-size: 12px !important;
}

.footerContainer {
  position: absolute;
  display: inline-flex !important;
  bottom: 0px;
  padding: 5px 25px;
  height: 48px;
  overflow-y: hidden;
  overflow-x: auto !important;
}

.footerContainerCpq {
  display: inline-flex !important;
  bottom: 0px;
  padding: 5px 25px;
  height: 48px;
  overflow-y: hidden;
  overflow-x: auto !important;
}

.footerButton {
  float: left;
  min-width: 100px !important;
  text-transform: none !important;
  margin-right: 8px !important;
  line-height: 1 !important;
}

.footerWarningContainer {
  display: flex;
  align-items: center;
}

.footerWarningIcon {
  margin-left: 8px !important;
  height: 40px !important;
  width: 30px !important;
}

.footerWarningText {
  font-size: 15px !important;
  margin-left: 8px !important;
}

.failedRestrictions {
  font-size: 12px !important;
}

.adminUIdata {
  height: calc(100vh - 100px);
}

.astSelectorTitle {
  font-weight: 600 !important;
  margin-bottom: 15px !important;
  color: var(--text-primary-color) !important;
}

.astSelectorCard {
  width: 100% !important;
  max-width: 340px !important;
  height: 170px !important;
  margin: 0 auto !important;
  margin-bottom: 8px !important;
}

.searchMethodCard {
  height: 220px !important;
}

.productItemCard {
  width: 100% !important;
  max-width: 340px !important;
  height: 370px !important;
  margin: 0 auto !important;
  margin-bottom: 8px !important;
  position: relative !important;
}

.selectedCard {
  outline: 2px solid var(--netzsch-green);
}

.accordionResultListContainer {
  height: 500px;
  padding-bottom: 50px;
}

.accordionResultListSwitch {
  text-align: left;
}

.resultListErrorIconContainer {
  width: 100%;
  white-space: pre-line;
  word-wrap: break-word;
  font-size: 12px;
}

.resultListErrorIcon {
  height: 17px !important;
  width: 17px !important;
}

.resultListErrorIcon:hover {
  cursor: pointer !important;
}

.resultListHeader {
  display: inline-block;
  max-width: min-content;
  white-space: pre-line;
  line-height: 16px;
}

.resultListHeaderExtended {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}

.resultListElastomer {
  font-size: 12px;
  text-align: left;
  display: inline-block;
  max-width: min-content;
  white-space: pre-line;
  line-height: 12px;
}

.resultListStator {
  width: 100px;
  white-space: pre-line;
  word-wrap: break-word;
  font-size: 12px;
  text-align: left;
}

.resultDataTable {
  min-width: 600px;
  border-collapse: separate !important;
}

.dpUnselectedCell {
  border: 1px solid var(--nav-menu-bgr-color);
}

.dpHeader {
  border: 1px solid var(--nav-menu-bgr-color);
  position: relative;
}
.dpHeaderSelected {
  position: relative;
  border-left: 1px solid var(--netzsch-green) !important;
  border-right: 1px solid var(--netzsch-green) !important;
  border-top: 1px solid var(--netzsch-green) !important;
}

.dpSelectedIcon {
  size: small;
  color: var(--netzsch-green);
  right: 0;
  position: absolute;
}

.selectedDp {
  box-sizing: border-box !important;
  border-left: 1px solid var(--netzsch-green) !important;
  border-right: 1px solid var(--netzsch-green) !important;
}

.selectedDpBottom {
  box-sizing: border-box !important;
  border-left: 1px solid var(--netzsch-green) !important;
  border-right: 1px solid var(--netzsch-green) !important;
  border-bottom: 1px solid var(--netzsch-green) !important;
}

.resultDataTableButton {
  display: inline-block;
  width: 100px;
  height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}

.chartBox {
  width: 605px;
  height: 460px;
  border: 1px solid var(--text-disabled-color);
  background-color: white;
  margin: 0px 5px 15px 0px;
  padding-bottom: 10px;
}

.chartBoxPdf {
  width: 806px;
  background-color: white;
  color: white;
  position: relative;
}

.adminUserDetailsTitle {
  text-align: left;
  margin-bottom: 10px !important;
  margin-top: 15px !important;
}

.graphsLoaderContainer {
  width: 100px !important;
  height: 100px !important;
  margin: 0 auto;
  overflow: hidden;
}

.graphsLoader {
  width: 100px !important;
  height: 100px !important;
}

.activeTab {
  color: var(--netzsch-green);
}

.mainTabHeader {
  box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.3);
  width: fit-content !important;
  /* overflow-x: auto !important; */
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.mainTabHeader .MuiTabs-indicator {
  min-width: 80px;
}

.mainTabHeader .MuiButtonBase-root {
  min-width: 80px !important;
}

.productTabContainer {
  min-height: auto !important;
  height: 30px;
}

.productDataTabHeader {
  width: 425px !important;
}

.adminPanelLayout {
  display: flex;
  flex-wrap: wrap;
}

.adminListContainer {
  width: 100%;
  padding-right: 7px;
}

.adminDetailsContainer {
  width: var(--admin-details-container-width);
}

.adminList {
  height: 600px;
  overflow: auto;
}

.adminListFooter {
  height: 60px;
  padding-top: 7px;
  display: flex;
  width: 100vw;
  overflow-x: auto;
}

.tabPanel {
  padding: 8px 0px !important;
}

.tabPanelDetails {
  overflow: auto;
  height: calc(100vh - 200px);
}

/* refers to mui custom theme - sm - size */
@media only screen and (min-width: 640px) {
  .unitDialogInputsContainer {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fit, 350px);
    grid-template-rows: repeat(auto-fit, 75px);
  }

  .productItemCard {
    max-width: 260px !important;
    margin: 8px !important;
  }

  .astSelectorCard {
    max-width: 240px !important;
    margin: 8px !important;
  }

  .dutyPointCard {
    width: var(--duty-point-sm-width);
  }

  .addDutyPointContainer {
    width: var(--duty-point-sm-width);
  }

  .textFieldInput {
    width: 300px;
  }

  .selectInput {
    width: 300px;
  }

  .commonInputContainer {
    width: 300px;
  }

  .selectContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .textFieldInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .labelContainer {
    width: var(--attribute-label-sm-width);
    height: fit-content !important;
  }
}

/* refers to mui custom theme - lg - size */
@media only screen and (min-width: 1024px) {
  .analyzerPage {
    margin-top: 0px;
  }

  .navigationMenu {
    width: var(--sidebar-lg-width);
    height: 100vh;
  }

  .detailsContainer {
    width: calc(100vw - var(--sidebar-lg-width));
    height: 100vh;
  }

  .sidebarAccordionContainer {
    height: calc(100vh - 130px);
  }

  .sidebarAccordionContainerCpq {
    height: calc(100vh - 170px);
  }

  .attributesContainer {
    padding-top: 40px;
    padding-left: 25px;
    padding-right: 25px;
  }

  .labelContainer {
    width: var(--attribute-label-lg-width);
  }

  .hintContainer {
    width: calc(
      100vw - var(--sidebar-lg-width) - var(--attribute-label-lg-width) - var(--select-input-width) -
        60px
    );
  }
}

@media only screen and (min-width: 1270px) {
  .adminListContainer {
    width: calc(100vw - var(--admin-details-container-width) - 50px);
  }
}

/* refers to mui custom theme - xl - size */
@media only screen and (min-width: 1536px) {
  .navigationMenu {
    width: var(--sidebar-xl-width);
  }

  .detailsContainer {
    width: calc(100vw - var(--sidebar-xl-width));
  }

  .sideBarHeader {
    width: var(--sidebar-xl-width);
  }

  .labelContainer {
    width: var(--attribute-label-xl-width);
  }

  .hintContainer {
    width: calc(
      100vw - var(--sidebar-xl-width) - var(--attribute-label-xl-width) - var(--select-input-width) -
        60px
    );
  }
}
